import React from 'react';
import Helmet from 'react-helmet';

import favicon32 from '../../../static/favicon/favicon-32x32.png'
import favicon64 from '../../../static/favicon/favicon-64x64.png'
import appleTouch from '../../../static/favicon/apple-touch-icon.png'

export default function SEO({ title, description, image }) {
    const url = "https://play-retail.com"

    const truncate = (input) => input.length > 5 ? `${input.substring(0, 140)}...` : input;

	return <Helmet>

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-YKC75KBBWE"></script>
        <script>
            {`
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
    
                gtag('config', 'G-YKC75KBBWE')
            `}     
        </script>
        <title>{title}</title>
        <meta name="title" content={title}/>
        <meta name="description" content={truncate(description)}/>
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouch}/>
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32}/>
        <link rel="icon" type="image/png" sizes="64x64" href={favicon64}/>
        <link rel="manifest" href="/site.webmanifest"/>
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
        <style>
            @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500;600;700;800&display=swap');
        </style>
        <meta name="msapplication-TileColor" content="#da532c"/>
        <meta name="theme-color" content="#ffffff"></meta>

        <meta property="og:type" content="website"/>
        <meta property="og:url" content={url}/>
        <meta property="og:title" content={title}/>
        <meta property="og:description" content={truncate(description)}/>
        <meta property="og:image" content={`${url}/seo/${image}`}/>
        
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:url" content={url}/>
        <meta property="twitter:title" content={title}/>
        <meta property="twitter:description" content={truncate(description)}/>
        <meta property="twitter:image" content={`${url}/seo/${image}`}/>

    </Helmet>;
}
