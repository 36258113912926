import * as React from 'react';

import Layout from '../../components/Layout';
import ArticleList from '../../components/ArticleList';

export default class ArticlesIndexPage extends React.Component {
	render() {
		return (
			<Layout>
				<ArticleList />
			</Layout>
		);
	}
}
