import { css as styled } from '@emotion/react';
import { alpha } from '@mui/material';

const styling = theme => styled`

    padding: ${theme.spacing(20)};

    ${theme.breakpoints.down('lg')} {
        padding: ${theme.spacing(20, 10)};
    }

    ${theme.breakpoints.down(900)} {
        display: flex;
        flex-direction: column;
        flex-flow: column;
        justify-content: center;
        padding: 50px 55px;
    }

    ${theme.breakpoints.down('md')} {
        padding: ${theme.spacing(20, 5)};
    }

    ${theme.breakpoints.down('sm')} {
        padding: ${theme.spacing(20, 2)};
    }

    .list-title {
        padding-bottom: ${theme.spacing(8)};
        max-width: 100%;
        margin: auto;
        color: #EB5558;
        font-size: 3rem;

        ${theme.breakpoints.up('xl')} {
            font-size: 4rem;
        }

        ${theme.breakpoints.down(900)} {
            font-size: 2rem;
            margin-bottom: 50px;
            padding: 0;
        }
    }

    .list {
        flex-wrap: wrap;
        padding: ${theme.spacing(0, 4)};

        ${theme.breakpoints.down(900)} {
            max-width: 100%;
            margin: auto;
            padding: 0 !important;
        }
    }

    .list-item {
        max-width: 373px;
        margin: 0;
        padding: ${theme.spacing(5, 0)};
        padding-right: ${theme.spacing(2)};

        ${theme.breakpoints.down('lg')} {
            padding: ${theme.spacing(5, 0)};
            max-width: 50%;
        }

        ${theme.breakpoints.down(900)} {
            align-items: center;
            padding: 30px 0;
            margin: auto;
        }

        ${theme.breakpoints.down('md')} {
            max-width: 100%;
            width: 100%;
        }
    }

    .gatsby-image-wrapper {
        max-width: 300px !important;
        width: auto !important;
        height: auto !important;

        ${theme.breakpoints.down(900)} {
            max-width: 350px !important;
        }
    }

    .image {
        max-width: 300px;

        ${theme.breakpoints.down(400)} {
            max-width: 250px;
        }
    }

    .item-content {
        ${theme.breakpoints.down('lg')} {
            max-width: 80%;
        }

        ${theme.breakpoints.down(900)} {
            text-align: center;
            max-width: 100%;
        }
    }
    
    .title {
        font-size: 1.5rem;
        text-decoration: none;
        color: #000;

        ${theme.breakpoints.down('sm')} {
            margin-bottom: ${theme.spacing(2)};
        }
    }

    .post-heading {
        line-height: 2rem !important;

        ${theme.breakpoints.down('md')} {
            line-height: 1.4rem !important;
        }
    }

    .subheading {
        margin-bottom: ${theme.spacing(1)};
        position: relative;
        top: -8px;
        color: #000;
        font-family: 'Comfortaa', sans-serif !important;
        font-weight: 800 !important;

        ${theme.breakpoints.down(900)} {
            margin-top: ${theme.spacing(0.5)};
            top: 0;
        }

        ${theme.breakpoints.down(600)} {
            line-height: 1.5rem !important;
        }
    }

    .description {
        max-width: 373px;
        width: 80%;
        min-height: 75px;
        font-family: 'Comfortaa', sans-serif !important;
        font-size: 0.8rem;
        font-weight: 800 !important;
        color: #000;

        ${theme.breakpoints.down(900)} {
            min-height: auto;
            max-width: auto;
            width: 300px;
        }

        ${theme.breakpoints.down(600)} {
            min-height: auto;
            width: 95%;
            margin: auto;
        }
    }

    .button-grid {
        ${theme.breakpoints.down(900)} {
            width: 100%;
            text-align: center;
        }
    }

    .button-link {
        text-decoration: none;
    }

    .button {
        text-decoration: none;
        text-transform: capitalize;
        background-color: #EB5558;
        padding-bottom: 2px;
        color: #fff;
        font-weight: 400;
        font-size: 0.9rem;
        border-radius: 50px;
        width: 100px;
        height: 50px;

        ${theme.breakpoints.down(900)} {
            width: 300px;
            margin: auto;
        }

        ${theme.breakpoints.down(480)} {
            width: 100%;
        }
    }

    .button: hover {
        background-color: #222532;
    }

    h1 {
        line-height: 2.5rem;
        margin-top: 20px;

        ${theme.breakpoints.down('md')} {
            margin-top: 10px;
        }
    }

`;

export default styling;
