import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import SEO from '../SEO';
import { Button, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import styles from './styles';

import Image from '../Image';

const ArticleList = ({ data }) => {
	const theme = useTheme();

	const mobile = useMediaQuery(theme.breakpoints.down('md'));

	const { edges: posts } = data.allMarkdownRemark;

	return (
		<Container css={styles(theme)}>
			<SEO
				title="Play Retail Ltd | Retail Design Agency | London"
				description="We’re Play, a strategic retail design agency who utilises technology to deliver experiential shopper experiences, disrupting the future of retail. "
			/>
			<Typography className="list-title" component={'h1'} mb={4}>
				Our work.
			</Typography>
			<Grid
				container
				direction={mobile ? 'column' : 'row'}
				className="list"
				justifyContent="flex-start"
				spacing={4}
			>
				{posts &&
					posts.map(({ node: post }) => (
						<Grid
							container
							className="list-item"
							direction="column"
							justifyContent="flex-start"
							key={post.id}
						>
							<Grid item>
								{post.frontmatter.featuredimage ? (
									<Link to={post.fields.slug} className="image">
										<Image
											className="gatsby-image"
											src={post.frontmatter.featuredimage}
										/>
										{/* <PreviewCompatibleImage
											className="gatsby-image"
											imageInfo={{
												image: post.frontmatter.featuredimage,
												alt: `featured image thumbnail for article ${post.frontmatter.title}`
											}}
										/> */}
										{/* <img className="image" src={post.frontmatter.featuredimage.childImageSharp.fluid.src} /> */}
									</Link>
								) : null}
							</Grid>
							<Grid className="item-content" item>
								<Typography className="post-heading" component={'h1'} mt={4}>
									<Link
										className="title has-text-primary is-size-4"
										to={post.fields.slug}
									>
										{post.frontmatter.listHeading}
									</Link>
									<Typography className="subheading" mb={1} mt={0}>
										{post.frontmatter.subheading}
									</Typography>
								</Typography>
								<Typography className="description" mb={4}>
									{post.frontmatter.description}
								</Typography>
							</Grid>
							{/* <Grid className="button-grid" item>
								<Link className="button-link" to={post.fields.slug}>
									<Button className="button">View</Button>
								</Link>
							</Grid> */}
						</Grid>
					))}
			</Grid>
		</Container>
	);
};

ArticleList.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array
		})
	})
};

export default function Article() {
	return (
		<StaticQuery
			query={graphql`
				query ArticleListQuery {
					allMarkdownRemark(
						sort: { order: DESC, fields: [frontmatter___templateKey] }
						filter: { frontmatter: { templateKey: { eq: "article" } } }
					) {
						edges {
							node {
								excerpt(pruneLength: 400)
								id
								fields {
									slug
								}
								frontmatter {
									templateKey
									heading
									listHeading
									subheading
									description
									date
									featuredimage {
										childImageSharp {
											gatsbyImageData(
												width: 1000
												quality: 100
												layout: CONSTRAINED
											)
										}
									}
								}
							}
						}
					}
				}
			`}
			render={(data, count) => <ArticleList data={data} count={count} />}
		/>
	);
}
